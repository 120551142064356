<template>
	<div class="basket">
		<v-container v-if="isEmpty" class="text-center">
			<h1 class="h3 mb-16">Ваша корзина пуста</h1>
		</v-container>
		<div v-else class="basket__wrapper mx-auto padding-top-main">
			<basket-items :items="items"/>
			<basket-delivery-warning/>
			<basket-form :items="items"/>
		</div>
	</div>
</template>

<script>
//import {yandexMap, ymapMarker, loadYmap} from 'vue-yandex-maps';
import BasketForm from "@/views/Basket/basket-form";
import BasketItems from "@/views/Basket/basket-items";
import city from "@/mixins/city";
import BasketDeliveryWarning from "@/views/Basket/basket-delivery-warning.vue";

export default {
	name: "basket",
	components: {
		BasketDeliveryWarning,
		BasketItems,
		BasketForm,
	},
	mixins: [city],
	computed: {
		isEmpty() {
			return !this.$store.state.session?.basket?.length;
		},
		items() {
			return this.checkBasketItemsAvailability(this.$store.state.session?.basket);
		},
	},

}
</script>

<style lang="scss">
.basket {
	//margin-top: 128px;
	margin-top: 168px;

	/*&__with-banner {
		margin-top: 168px;
	}*/

	&__wrapper {
		position: relative;
		@include up($sm) {
			max-width: 952px;
		}
		@include up($lg) {
			max-width: 1048px;
		}

	}


}
</style>